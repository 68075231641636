import { contactUsApi } from '@/components/action/easycampus';
import { ERRORMESSAGE } from '@/utils/constant';
import { validateAboutYou, validateEmail, validateMobile, validateName } from '@/utils/validation';
import React, { useRef, useState } from 'react'
import Popup from './popup';
import Link from 'next/link';


const Contactuscoman = () => {
  const [isActiveform, setIsActiveform] = useState(false);

  const handleEnquiry = () => {
    setIsActiveform(!isActiveform);
  }

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [aboutYou, setAboutYou] = useState("");
  const [addForm, setAddForm] = useState("");
  const [demoForm, setDemoForm] = useState(false);
  const [formMessage, setFormMessage] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false)

  // Error Messages
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [mobileErrorMessage, setMobileErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [aboutYouErrorMessage, setAboutYouErrorMessage] = useState("");

  // Ref focus for input field
  const nameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const mobileInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const aboutYouInputRef = useRef(null);

  // HANDLE methods
  const handleName = (e) => {
    if (e.target.value.trim() === "") {
      setNameErrorMessage(ERRORMESSAGE.NAME_IS_REQUIRED);
      nameInputRef.current.focus();
    } else {
      setNameErrorMessage(validateName(e.target.value.trim()));
      nameInputRef.current.focus();
    }
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    if (e.target.value.trim() === "") {
    }
    else {
      setLastNameErrorMessage(validateName(e.target.value.trim()));
      lastNameInputRef.current.focus();
    }
    setLastName(e.target.value);
  };

  const handleMobile = (e) => {
    if (e.target.value.trim() === "") {
      setMobileErrorMessage(ERRORMESSAGE.MOBILE_NUMBER_IS_REQURED);
      mobileInputRef.current.focus();
    } else {
      setMobileErrorMessage(validateMobile(e.target.value.trim()));
      mobileInputRef.current.focus();
    }
    setMobile(e.target.value);
  };

  const handleEmail = (e) => {
    if (e.target.value.trim() === "") {
      setEmailErrorMessage(ERRORMESSAGE.EMAIL_IS_REQURED);
      emailInputRef.current.focus();
    } else {
      setEmailErrorMessage(validateEmail(e.target.value.trim()));
      emailInputRef.current.focus();
    }
    setEmail(e.target.value);
  };

  const handleaboutYou = (e) => {
    if (e.target.value.trim() === "") {
      setAboutYouErrorMessage(ERRORMESSAGE.MESSAGE_IS_REQUIRED);
      aboutYouInputRef.current.focus();
    } else {
      setAboutYouErrorMessage(validateAboutYou(e.target.value.trim()));
      aboutYouInputRef.current.focus();
    }
    setAboutYou(e.target.value);
  }

  const handleDemoForm = () => {
    setAddForm("add");
    setDemoForm(true);
  };

  const handleCombined = () => {
    handleFormSubmit();
    handleDemoForm();
  };

  // form Submit 
  // const handleFormSubmit = async (e) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      setNameErrorMessage(ERRORMESSAGE.NAME_IS_REQUIRED);
    } else if (name) {
      setNameErrorMessage(validateName(name.trim()));
    }

    if (lastName) {
      setLastNameErrorMessage(validateName(lastName.trim()));
    }

    if (!mobile) {
      setMobileErrorMessage(ERRORMESSAGE.MOBILE_NUMBER_IS_REQURED);
    } else if (mobile) {
      setMobileErrorMessage(validateMobile(mobile.trim()));
    }

    if (!email) {
      setEmailErrorMessage(ERRORMESSAGE.EMAIL_IS_REQURED);
    } else if (email) {
      setEmailErrorMessage(validateEmail(email.trim()));
    }

    if (!aboutYou) {
      setAboutYouErrorMessage(ERRORMESSAGE.MESSAGE_IS_REQUIRED);
    } else if (aboutYou) {
      setAboutYouErrorMessage(validateAboutYou(aboutYou.trim()));
    }

    // For Focus

    if (!name) {
      nameInputRef.current.focus();
      return;
    } else if (name) {
      if (validateName(name.trim())) {
        nameInputRef.current.focus();
        return;
      }
    }

    if (!email) {
      emailInputRef.current.focus();
      return;
    } else if (email) {
      if (validateEmail(email.trim())) {
        emailInputRef.current.focus();
        return;
      }
    }
    if (!mobile) {
      mobileInputRef.current.focus();
      return;
    } else if (mobile) {
      if (validateMobile(mobile.trim())) {
        mobileInputRef.current.focus();
        return;
      }
    }

    if (!aboutYou) {
      aboutYouInputRef.current.focus();
      return;
    } else if (aboutYou) {
      if (validateAboutYou(aboutYou.trim())) {
        aboutYouInputRef.current.focus();
        return;
      }
    }

    let formData = {
      firstName: name,
      lastName: lastName,
      email: email,
      mobile: mobile,
      message: aboutYou,
    }
    setButtonDisable(true)
    contactUsDataSubmit(formData);
    console.log("formData", formData);
    // await contactUsDataSubmit(formData);
    // showToast.success("Thank you for submitting the form!");
    // console.log("formData",formData);

  };
  const contactUsDataSubmit = async (formData) => {
    console.log("lets talk", formData);
    try {

      const response = await contactUsApi(formData);

      if (response.data.hasError == false) {
        setFormMessage(response.data);
        console.log("lets talk formsumbit", response.data.data);
        setDemoForm(true)
        setName("");
        setLastName("");
        setMobile("");
        setEmail("");
        setAboutYou("");
        setButtonDisable(false)
      }

      if (response.data.hasError === true) {
        setFormMessage(response.data);
        setDemoForm(true)
        setName("");
        setLastName("");
        setMobile("");
        setEmail("");
        setAboutYou("");
        setButtonDisable(false)
      }

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {demoForm && (
        <Popup
          isOpen={demoForm}
          onClose={() => setDemoForm(false)}
          message={formMessage} />
      )}


      <div className='bg-[#93BDFF] text-[#fff] rounded-l-[10px] top-[20%] -right-0 lg:-right-2 -translate-1/2 pr-1 py-1 fixed z-[100]'>
        <div className='py-2 px-1 rounded-full flex justify-center'>
          <Link href="https://api.whatsapp.com/send?phone=+919911868833&text=Hii!" rel="noopener noreferrer">
            <svg className='h-8 w-8' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M4.25895 16.5936C3.14076 14.7086 2.74916 12.4802 3.15768 10.3269C3.56621 8.17363 4.74675 6.24353 6.47764 4.89906C8.20852 3.55459 10.3707 2.88823 12.5581 3.02511C14.7455 3.16199 16.8078 4.0927 18.3575 5.64247C19.9073 7.19223 20.838 9.25445 20.9749 11.4419C21.1118 13.6293 20.4455 15.7915 19.101 17.5224C17.7565 19.2532 15.8264 20.4338 13.6732 20.8423C11.5199 21.2509 9.29149 20.8593 7.40649 19.7411L7.40651 19.741L4.29808 20.6291C4.16948 20.6659 4.03338 20.6676 3.90391 20.634C3.77443 20.6005 3.65628 20.5329 3.56171 20.4383C3.46713 20.3437 3.39956 20.2256 3.36601 20.0961C3.33246 19.9666 3.33415 19.8306 3.37089 19.7019L4.25902 16.5935L4.25895 16.5936Z" fill="#4ACA59" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.1607 16.231C13.2883 16.2321 12.4243 16.0707 11.6181 15.756C10.8119 15.4412 10.0793 14.9793 9.46245 14.3967C8.84558 13.8141 8.3565 13.1223 8.02322 12.3609C7.68995 11.5995 7.51903 10.7835 7.52027 9.95956C7.52224 9.37963 7.7677 8.82412 8.20278 8.41488C8.63787 8.00564 9.22707 7.77609 9.84113 7.77658V7.77658C9.94185 7.77658 10.0408 7.80173 10.1279 7.84947C10.215 7.89722 10.2872 7.96586 10.3372 8.04845L11.3062 9.65003C11.3647 9.74662 11.3948 9.85616 11.3936 9.9674C11.3923 10.0786 11.3597 10.1875 11.2991 10.2829L10.5209 11.5079C10.9212 12.3471 11.6327 13.019 12.5212 13.3971V13.3971L13.8183 12.6622C13.9193 12.6049 14.0346 12.5741 14.1524 12.5729C14.2702 12.5718 14.3862 12.6002 14.4885 12.6554L16.1843 13.5706C16.2717 13.6178 16.3444 13.686 16.395 13.7683C16.4455 13.8505 16.4722 13.944 16.4722 14.0391V14.0391C16.4704 14.6184 16.2266 15.1736 15.7938 15.584C15.361 15.9944 14.7741 16.2269 14.1607 16.231V16.231Z" fill="white" />
            </svg>
          </Link>
        </div>
        <div className='py-2 px-1.5  rounded-full  flex justify-center '>
          <Link href="tel:+919911868833" rel="noopener noreferrer">
<svg className='h-8 w-8' xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<path d="M15 26.25C21.2132 26.25 26.25 21.2132 26.25 15C26.25 8.7868 21.2132 3.75 15 3.75C8.7868 3.75 3.75 8.7868 3.75 15C3.75 21.2132 8.7868 26.25 15 26.25Z" fill="#152B54" stroke="#152B54" stroke-miterlimit="10"/>
<path d="M16.9199 9.13965C17.8642 9.39362 18.7252 9.89125 19.4167 10.5827C20.1081 11.2742 20.6057 12.1352 20.8597 13.0795" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.3438 11.2925C16.9104 11.4449 17.427 11.7435 17.8419 12.1583C18.2567 12.5732 18.5552 13.0898 18.7076 13.6564" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.2593 15.0456C12.8372 16.2269 13.7947 17.1801 14.9786 17.7525C15.0652 17.7936 15.161 17.8114 15.2566 17.8041C15.3522 17.7969 15.4443 17.7649 15.5237 17.7113L17.2669 16.5489C17.344 16.4975 17.4327 16.4661 17.525 16.4576C17.6173 16.4491 17.7102 16.4638 17.7954 16.5003L21.0566 17.898C21.1674 17.945 21.2599 18.0268 21.3202 18.131C21.3804 18.2352 21.4052 18.3562 21.3907 18.4757C21.2877 19.2823 20.8941 20.0236 20.2838 20.561C19.6735 21.0983 18.8882 21.3947 18.075 21.3948C15.5636 21.3948 13.1549 20.3971 11.379 18.6212C9.60315 16.8453 8.60547 14.4366 8.60547 11.9252C8.60551 11.112 8.90194 10.3267 9.43927 9.71644C9.97659 9.10611 10.7179 8.71257 11.5245 8.60949C11.644 8.59503 11.765 8.61979 11.8692 8.68005C11.9734 8.74031 12.0552 8.8328 12.1022 8.94359L13.5011 12.2076C13.5373 12.2921 13.552 12.3841 13.544 12.4756C13.536 12.5672 13.5055 12.6553 13.4552 12.7321L12.2968 14.5021C12.2441 14.5817 12.2129 14.6736 12.2064 14.7689C12.1998 14.8642 12.218 14.9595 12.2593 15.0456Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </Link>
        </div>

      </div>


      {/* Sidebar and Toggle Button Container */}
      <div className={`sidebar-contact  ${isActiveform ? 'activeform' : ''}`}>
        {/* Toggle Button */}
        <div
          className={`toggle font-light text-xl ${isActiveform ? 'activeform' : ''}`}
          onClick={handleEnquiry}
        >
          Enquiry Now
        </div>
        {/* Sidebar Content */}
        <h3 className='text-center'>Enquiry Form</h3>
        <form action="#" method="POST" onSubmit={handleFormSubmit} className="mt-6">
          <div className="mb-3">
            <input
              type="text"
              name="first_name"
              placeholder="Full Name"
              value={name}
              ref={nameInputRef}
              onChange={handleName}
              className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 transition duration-150" />
            <div
              id="nameErrorMessage"
              className="text-red-500 text-sm"
            >
              {nameErrorMessage}
            </div>
          </div>
          <div className="mb-3">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              ref={emailInputRef}
              onChange={handleEmail}
              className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 transition duration-150" />
            <div
              id="EmailErrorMessage"
              className="text-red-500 text-sm">
              {emailErrorMessage}
            </div>
          </div>
          <div className="mb-3">
            <input
              type="text"
              name="phone"
              placeholder="Phone Number"
              ref={mobileInputRef}
              value={mobile}
              onChange={handleMobile}
              pattern="[6789][0-9]{9}"
              minLength={10}
              maxLength={10}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
              }}
              className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 transition duration-150"
            />
            <div id="mobileErrorMessage" className="text-red-500 text-sm">
              {mobileErrorMessage}
            </div>
          </div>
          <div className="mb-3">
            <textarea name="message"
              placeholder="Message..." rows="5"
              onChange={handleaboutYou}
              ref={aboutYouInputRef}
              value={aboutYou}
              minLength={20}
              className="w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 transition duration-150"></textarea>
            <div
              id="aboutYouErrorMessage"
              className="text-red-500 text-sm"
            >
              {aboutYouErrorMessage}
            </div>
          </div>
          <div className="mt-6">
            <button type="submit"
              disabled={buttonDisable}
              onClick={handleFormSubmit}
              className={`w-full px-4 py-2 text-white ${buttonDisable == true ? "bg-[#62656b]" : "bg-[#1e336a]"} bg-[#1e336a] rounded-md hover:bg-[#51A9FF] focus:outline-none focus:bg-indigo-600 transition duration-150 ease-in-out`}>Send
              Message</button>
          </div>
        </form>
      </div>
    </>
  )
}

export default Contactuscoman